import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const slideUpAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: '18%',
          width: '1100px',
          height: '100%',
          maxWidth: 'fit-content',
          transform: 'scale(1)',
          overflow: 'hidden'
        })
      ], { optional: true }),
      group([
        query(':enter', [
          style({ transform: 'translateY(100%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateY(0%)' }))
        ], { optional: true }),
        query(':leave', [
          style({ transform: 'translateY(0%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateY(-100%)' }))
        ], { optional: true }),
      ])
    ])
  ]);

export const slideUpBottomAnimation =
  trigger('slideInBottom', [
    transition(':enter', [
      style({ transform: 'translateY(100%)' }),
      animate('500ms ease-out', style({ transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateY(0)' }),
      animate('500ms ease-out', style({ transform: 'translateY(100%)' })),
    ])
  ]);

export const fadeInAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: '18%',
          height: '100%',
          maxWidth: 'fit-content',
          opacity: 0
        })
      ], { optional: true }),
      query(':enter', [
        animate('0.2s ease-in-out', style({ opacity: 1 }))
      ], { optional: true }),
      query(':leave', [
        animate('0.2s ease-in-out', style({ opacity: 0 }))
      ], { optional: true }),
    ])
  ]);


export const fadeInBottomAnimation =
  trigger('fadeInBottom', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('500ms ease-out', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      animate('500ms ease-out', style({ opacity: 0 })),
    ])
  ]);
