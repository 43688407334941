import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import { UserContainerComponent } from './user/user-container/user-container.component';
import {
  GettingStartedContainerComponent
} from './getting-started/getting-started-container/getting-started-container.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: '',
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule)
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "user",
    component: UserContainerComponent,
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  {
    path: 'getting-started',
    component: GettingStartedContainerComponent,
    loadChildren: () => import("./getting-started/getting-started.module").then((m) => m.GettingStartedModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
