import {Injectable} from '@angular/core';
import { BehaviorSubject, catchError, mergeMap, Observable, of, Subject, takeUntil, throwError } from 'rxjs';
import { User, UserService } from '@wasmachen/api-connector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Session from "supertokens-web-js/recipe/session";
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  hasSessionSubject = new BehaviorSubject<boolean>(false);
  hasSession$ = this.hasSessionSubject.asObservable();
  updateUser$: Subject<boolean> = new Subject<boolean>();
  userId$ = this.hasSession$
    .pipe(
      mergeMap(hasSession => hasSession ? Session.getUserId() : of(null)),
    );

  constructor(
    private userService: UserService
  ) {
    this.hasSessionSubject.subscribe((value) => {
      if(!this.user$?.value){
        this.getAndSetUserProfile()
      }
    });

    this.updateUser$.subscribe((value) => {
      this.getAndSetUserProfile()
    });
  }

  async checkForSession(): Promise<boolean> {
      const doesSuperTokensSessionExist = await Session.doesSessionExist();
      this.hasSessionSubject.next(doesSuperTokensSessionExist);
      return doesSuperTokensSessionExist;
  }

  signOut(): Promise<void> {
    return Session.signOut()
      .then(() => this.hasSessionSubject.next(false));
  }

  private async getAndSetUserProfile() {
    this.userService.getUser().pipe(
      untilDestroyed(this),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return of(null); // or return an empty observable as a fallback
        }
        // For other errors, you can decide whether to rethrow or handle them differently
        return null; // Rethrow the error to let it propagate
      })
    ).subscribe((user) => {
      this.user$.next(user);
    });
  }
}
