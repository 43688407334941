<div class="grid grid-nogutter surface-section px-4 py-4 md:px-6 lg:px-8 border-top-1 surface-border border-round-top-3xl ">
  <div class="col-12 lg:col-6 lg:border-right-1 surface-border">
    <img src='../../../../assets/icons/Logo.svg' class="w-2rem lg:w-6rem mx-auto lg:mx-0" alt="Wasmachen logo">
    <span class="text-primary font-bold text-2xl block mt-4 mr-3">Wasmachen.at</span>
    <span class="text-500 block mt-4">Ending Boredom </span>
  </div>
  <div class="col-12 md:col-6 lg:col-3 mt-4 lg:mt-0 lg:pl-4 flex flex-column">
    <span class="text-primary text-xl font-bold font-medium block">Company</span>
    <ul class="list-none p-0">
      <li><a tabindex="0" class="text-600 hover:text-primary transition-duration-150 mt-3 block" routerLink="about">About</a></li>
      <li><a tabindex="0" class="text-600 hover:text-primary transition-duration-150 mt-3 block" routerLink="privacy-policy">Privacy Policy</a></li>
      <li><a tabindex="0" class="text-600 hover:text-primary transition-duration-150 mt-3 block" routerLink="impressum">Impressum</a></li>
      <li><a tabindex="0" class="text-600 hover:text-primary transition-duration-150 cursor-pointer mt-3 block" routerLink='partners'>Partners</a></li>
    </ul>
  </div>
  <div class="col-12 lg:col-3 md:col-6 flex mt-4 lg:mt-0 lg:pl-4 flex-column">
    <span class="text-primary text-xl font-bold font-medium block">Account</span>
    <ul class="list-none p-0">
      <li><a tabindex="0" class="text-600 hover:text-primary transition-duration-150 cursor-pointer mt-3 block" routerLink='user/account'>Account</a></li>
      <li><a tabindex="0" class="text-600 hover:text-primary transition-duration-150 cursor-pointer mt-3 block" routerLink='discover'>Discover</a></li>
    </ul>
  </div>
</div>

<div class="surface-900 py-6 lg:py-4 md:px-6 lg:px-8 flex flex-column lg:flex-row justify-content-between align-items-center border-round-bottom-3xl">
  <ul class="list-none p-0 mb-0 flex flex-column md:flex-row flex-order-1 lg:flex-order-0 mt-4 lg:mt-0">
    <li class="mr-4 mt-3 lg:mt-0">
      <a tabindex="0" class="cursor-pointer text-0" routerLink="impressum">Impressum</a>
    </li>
  </ul>

  <div class="flex align-items-center flex-order-0 lg:flex-order-1">
    <a tabindex="0" class="cursor-pointer mr-3 lg:mt-0 block">
      <i class="pi pi-facebook surface-section p-1 text-sm border-circle text-primary"></i>
    </a>
    <a tabindex="0" class="cursor-pointer mr-3 lg:mt-0 block">
      <i class="pi pi-instagram surface-section p-1 text-sm border-circle text-primary"></i>
    </a>
    <a tabindex="0" class="cursor-pointer mr-3 lg:mt-0 block">
      <i class="pi pi-youtube surface-section p-1 text-sm border-circle text-primary"></i>
    </a>
    <a tabindex="0" class="cursor-pointer lg:mt-0 block">
      <i class="pi pi-envelope surface-section p-1 text-sm border-circle text-primary"></i>
    </a>
  </div>
</div>
