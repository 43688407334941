<p-dialog [(visible)]='show' [dismissableMask]='true' [modal]='true' [showHeader]='false'
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"
          contentStyleClass='border-round-top p-0' appendTo='body' (onShow)='input1.focus();'
        (onHide)="hide()">
  <div class='flex w-full surface-section align-items-center justify-content-between px-1 p-3'>
        <span class='p-input-icon-left w-full'>
            <i class='pi pi-search text-500'></i>
            <input
              (keyup)='search(getValue($event))'
              #input1 type='text' pInputText placeholder='Search event, commands and more...' [(ngModel)]='value1'
              class='w-full border-none shadow-none outline-none' />
        </span>
    <!--    <span class="border-1 p-1 surface-border border-round surface-100 mr-2 text-600 text-xs hidden md:block">⌘K</span>-->
  </div>
  <div class='border-y-1 surface-border px-3 py-3' *ngIf='events$ | async as events'>
    <div *ngIf='events?.length === 0 && events?.hits?.length === 0'>
      <h2 class='font-semibold text-xs text-500 mb-2 mt-0 px-1'>Type to search</h2>
    </div>
    <div *ngIf='events?.hits?.length > 0'>
      <h2 class='font-semibold text-xs text-500 mb-2 mt-0 px-1'>Results</h2>
      <ul class='list-none m-0 p-0'>
        <li *ngFor='let event of events?.hits;' (click)='openEvent(event?.slug)'
            class='select-none p-2 font-medium text-base cursor-pointer border-round-lg hover:surface-hover hover:text-primary'>
          <div [innerHTML]='event?._formatted?.name' *ngIf='event?._formatted?.name; else title'></div>
          <ng-template #title>
            <div>{{event?.name}}</div>
          </ng-template>
          <div class='card flex align-items-center gap-2 flex-wrap py-2'>
<!--            <p-chip [label]='cat' *ngFor='let cat of event.categories'></p-chip>-->
          </div>
        </li>
      </ul>
    </div>

    <div *ngIf='events?.hits?.length === 0'>
      <h2 class='font-semibold text-xs text-500 mb-2 mt-0 px-1'>No Results</h2>
    </div>
  </div>
  <div class='p-2' *ngIf='loggedIn$ | async else loggedOut'>
    <article
      class='flex w-full justify-content-between align-items-center mb-3 select-none cursor-pointer surface-border hover:surface-hover border-round-lg px-2 py-1 text-800 hover:text-primary'
      [routerLink]="['/user', 'profile']">
      <div class='flex align-items-center'>
                <span class='border-circle w-2rem h-2rem flex align-items-center justify-content-center surface-100'>
                    <i class='pi pi-user text-lg'></i>
                </span>
        <div class='ml-2'>
          <p class='font-semibold text-sm mt-0 mb-1'>My Profile</p>
          <p class='font-normal text-xs text-600 mt-0 mb-0'>Go to your profile</p>
        </div>
      </div>
      <!--<span class="hidden md:inline-block border-1 px-2 py-1 surface-border border-round surface-100 mr-2 text-600 text-xs">P</span>-->
    </article>

    <article
      class='flex w-full justify-content-between align-items-center select-none cursor-pointer surface-border hover:surface-hover border-round-lg px-2 py-1 text-800 hover:text-primary'
      [routerLink]="['/user', 'likes']">
      <div class='flex align-items-center'>
                <span class='border-circle w-2rem h-2rem flex align-items-center justify-content-center surface-100'>
                    <i class='pi pi-heart-fill text-lg'></i>
                </span>
        <div class='ml-2'>
          <p class='font-semibold text-sm mt-0 mb-1'>Likes</p>
          <p class='font-normal text-xs text-600 mt-0 mb-0'>View your likes</p>
        </div>
      </div>
    </article>
  </div>
  <ng-template #loggedOut>
    <article
      class='flex w-full justify-content-between align-items-center select-none cursor-pointer surface-border hover:surface-hover border-round-lg px-2 py-1 text-800 hover:text-primary'
      [routerLink]="['/auth']">
      <div class='flex align-items-center'>
                <span class='border-circle w-2rem h-2rem flex align-items-center justify-content-center surface-100'>
                    <i class='pi pi-sign-in text-lg'></i>
                </span>
        <div class='ml-2'>
          <p class='font-semibold text-sm mt-0 mb-1'>Login</p>
          <p class='font-normal text-xs text-600 mt-0 mb-0'>You are logged out</p>
        </div>
      </div>
    </article>
  </ng-template>
</p-dialog>
