import { Component, OnDestroy, AfterViewInit, Renderer2, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { environment } from '../../environments/environment';

@Component({
  selector: "app-auth",
  template: '<div id="supertokensui"></div>',
})
export class AuthComponent implements OnDestroy, AfterViewInit {

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngAfterViewInit() {
    this.loadScript('https://cdn.jsdelivr.net/gh/supertokens/prebuiltui@v0.46.0/build/static/js/main.57ef81a3.js');
  }

  ngOnDestroy() {
    const script = this.document.getElementById('supertokens-script');
    if (script) {
      script.remove();
    }
  }

  private loadScript(src: string) {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.id = 'supertokens-script';
    script.onload = () => {
      (window as any).supertokensUIInit("supertokensui", {
        appInfo: {
          appName: environment.supertokens.appName,
          apiDomain: environment.supertokens.apiDomain,
          websiteDomain: environment.supertokens.websiteDomain,
          apiBasePath: environment.supertokens.apiBasePath,
          websiteBasePath: "/auth"
        },
        style: `
          [data-supertokens~=container] {
              --palette-background: 255, 255, 255;
              --palette-inputBackground: 255, 255, 255;
              --palette-inputBorder: 41, 41, 41;
              --palette-textTitle: 0, 0, 0;
              --palette-textLabel: 0, 0, 0;
              --palette-textPrimary: 0, 0, 0;
              --palette-primaryBorder: 5, 165, 112;
              --palette-primary: 5, 165, 112;
              --palette-error: 173, 46, 46;
              --palette-textInput: 0, 0, 0;
              --palette-textLink: 0, 0, 0;
              --palette-superTokensBrandingText: 0,0,0;
              --palette-superTokensBrandingText: 0,0,0;
          }
          [data-supertokens~=button] {
              background-color: #05A570;
          }
          [data-supertokens~="button"]{

          }
          [data-supertokens~=superTokensBranding] {
            display: none;
          }
        `,
        getRedirectionURL: async (context) => {
          if (context.action === 'SUCCESS' && context.newSessionCreated) {
            if (context.redirectToPath !== undefined) {
              // we are navigating back to where the user was before they authenticated
              return context.redirectToPath;
            }
            if (context.createdNewUser) {
              return '/getting-started';
            } else {
              return '/discover';
            }
          }
          return undefined;
        },
        recipeList: [
          (window as any).supertokensUIEmailPassword.init(),
          (window as any).supertokensUIThirdParty.init({
            signInAndUpFeature: {
              providers: [
                (window as any).supertokensUIThirdParty.Google.init(),
                (window as any).supertokensUIThirdParty.Apple.init(),
              ]
            }
          }),
          (window as any).supertokensUISession.init(),
        ],
      });
    }
    this.renderer.appendChild(this.document.body, script);
  }
}
