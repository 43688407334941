export const environment = {
    production: true,
    basePath: '',
    domain: 'https://app.wasmachen.at',
    supertokens: {
      appName: "Wasmachen UI",
      apiDomain: 'https://app.wasmachen.at',
      websiteDomain: 'https://app.wasmachen.at',
      apiBasePath: '/api/auth',
      cookieDomain: '.wasmachen.at',
    }
};
