import { Component, OnInit } from '@angular/core';
import { debounceTime, filter, fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../auth/auth.service';
import { User, UserService } from '@wasmachen/api-connector';

@UntilDestroy()
@Component({
  selector: 'app-user-container',
  templateUrl: './user-container.component.html',
  styleUrls: ['./user-container.component.scss'],
  providers: [MessageService]
})
export class UserContainerComponent implements OnInit {
  isExtraLargeScreen: boolean = false;
  visible: boolean = false;
  user: User = null;

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    if (window?.innerWidth >= 1700) {
      this.isExtraLargeScreen = true;
    }
    fromEvent(window, 'resize')
      .pipe(untilDestroyed(this), debounceTime(150))
      .subscribe(() => {
        this.updateSize();
      });

    this.authService.user$
      .pipe(filter(user =>  !!user))
      .subscribe((user) => {
      this.user = user;
      if(!this.user.signUpComplete) {
        if (!this.visible) {
          this.messageService.add({
            key: 'confirm',
            sticky: true,
            severity: 'warn',
            summary: 'Please complete your profile'
          });
          this.visible = true;
        }
      }
    });

   //let data = JSON.parse(localStorage.getItem('data') ?? '');

    this.userService.findOrCreateUser().subscribe(() => {

    });
  }

  updateSize() {
    this.isExtraLargeScreen = window?.innerWidth >= 1700;
  }

  onConfirm() {
    this.messageService.clear('confirm');
    this.visible = false;
  }

  onReject() {
    this.messageService.clear('confirm');
    this.visible = false;
  }
}
