<div class="h-screen" style=''>
  <p-toast></p-toast>
  <app-nav-bar class="mt-2 lg:mt-0 m-0" ></app-nav-bar>
  <div
    class="flex flex-column full-variable-height w-full justify-content-between mt-2"
  >
    <router-outlet></router-outlet>
    <app-footer class='mt-2 lg:mt-4 m-0 lg:m-2' *ngIf='!isGettingStarted'></app-footer>
  </div>
</div>


