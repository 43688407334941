import { afterNextRender, AfterViewInit, Component, OnInit } from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import { BehaviorSubject, debounceTime, fromEvent, Observable, Subject } from 'rxjs';
import {Router} from "@angular/router";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User, UserService } from '@wasmachen/api-connector';

@UntilDestroy()
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, AfterViewInit {
  isMobile: boolean = false;
  visible1 = false;
  isLoggedIn = new BehaviorSubject<string>('');

  user$: Observable<User> = null;
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    afterNextRender(async () => {
      await this.authService.checkForSession();
      if (window?.innerWidth <= 992) {
        this.isMobile = true;
      }
      fromEvent(window, 'resize')
        .pipe(untilDestroyed(this), debounceTime(150))
        .subscribe(() => {
          this.updateSize();
        });
    });
  }

  ngOnInit() {

  }

  async ngAfterViewInit() {

    this.authService.userId$.subscribe((sessionId) => {
      this.isLoggedIn.next(sessionId ? sessionId : '');
    });

    this.user$ = this.authService.user$;
  }


  redirectToLogin() {
    window.location.href = "auth";
  }

  updateSize() {
    this.isMobile = window?.innerWidth <= 992;
  }

  async onLogout() {
    await this.authService.signOut();
    window.location.reload();
  }

}
