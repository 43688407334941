import {
  afterNextRender,
  Component,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { GeoLocationService } from './shared/services/geo-location.service';
import { MessageService } from 'primeng/api';
import { Notification, NotificationService } from './shared/services/notification.service';


@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = 'Wasmachen.at';
  isExtraLargeScreen: boolean = false;
  isGettingStarted = false;
  constructor(
    private route: Router,
    private geoLocationService: GeoLocationService,
    private notificationService: NotificationService,
    private messageService: MessageService) {
    afterNextRender(() => {
      this.ssrSetup()
      this.route.events.subscribe((data) => {
        this.ssrSetup();
      });

      this.geoLocationService.startLocationListener();

      this.notificationService.showNotification$
        .pipe(untilDestroyed(this))
        .subscribe((notification: Notification) => {
          this.showNotification(notification)
        });
    });
  }

  ngOnInit() {

  }

  ssrSetup(){
    setTimeout(() => {
      this.isGettingStarted = window.location.pathname.includes('getting-started') || window.location.pathname.includes('event-gpt');
    }, 100);
  }

  updateSize() {
    this.isExtraLargeScreen = window?.innerWidth >= 1700;
  }

  showNotification(notification: Notification){
    this.messageService.add({
      summary: notification.title,
      detail: notification.detail,
      severity: notification.severity.toLowerCase(),
      life: notification.life
    })
  }
}
