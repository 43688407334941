import { Component, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { TranslocoService } from '@jsverse/transloco';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-language-picker',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
  ],
  templateUrl: './language-picker.component.html',
  styleUrl: './language-picker.component.scss'
})
export class LanguagePickerComponent implements OnInit {

  languageOptions = [
    {
      code:'en',
      name:'EN',
      flag:'assets/flags/gb.svg'
    },
    {
      code:'de',
      name:'DE',
      flag:'assets/flags/de.svg'
    },
  ]


  currentLang = {
    code: '',
    name: '',
    flag: ''
  };


  constructor(private translocoService: TranslocoService) {
  }

  ngOnInit() {
    this.setDefaultLang();
  }

  setDefaultLang(){
    const _currentLang = this.translocoService.getActiveLang();
    this.findAndSetLang(_currentLang);
  }

  findAndSetLang(lang: string){
    const foundLang = this.languageOptions.find((val) => val.code === lang);
    if(foundLang){
      this.currentLang = foundLang;
    } else {
      this.currentLang = this.languageOptions[0];
    }
  }

  updateLanguage(event:any){
    const lang = event?.value || 'de';
    switch (lang.code) {
      case 'de':
        this.setLangauge('de');
        break;
      case 'en':
        this.setLangauge('en');
        break;
      case 'nl':
        this.setLangauge('nl');
        break;
      default:
        this.setLangauge('de');
    }
  }

  setLangauge(lang:string) {
    this.translocoService.setActiveLang(lang);
    this.findAndSetLang(lang);
  }

}
