import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SharedModule} from "./shared/shared.module";
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ApiConnectorConfiguration } from '@wasmachen/api-connector';
import { ToastModule } from 'primeng/toast';
import { MarkdownModule } from 'ngx-markdown';
import { TranslocoRootModule } from './transloco-root.module';
import { provideLottieOptions } from 'ngx-lottie';
import { AuthModule } from './auth/auth.module';
import { isPlatformBrowser } from '@angular/common';
import SuperTokens from 'supertokens-web-js/lib/build/supertokens';
import Session from 'supertokens-web-js/recipe/session';


@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      MarkdownModule.forRoot(),
      SharedModule,
      ToastModule,
      TranslocoRootModule, AuthModule], providers: [
    /* {
         provide: ErrorHandler,
         useValue: Sentry.createErrorHandler({
             showDialog: false, //TODO change this for popup dialog
         }),
     }, {
         provide: Sentry.TraceService,
         deps: [Router],
     },
     {
         provide: APP_INITIALIZER,
         useFactory: () => () => { },
         deps: [Sentry.TraceService],
         multi: true,
     },*/{
      provide: ApiConnectorConfiguration,
      useValue: new ApiConnectorConfiguration({
        basePath: environment.production
          ? environment.supertokens.apiDomain
          : environment.supertokens.apiDomain,
      }),
    },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideClientHydration(),
    ] })
export class AppModule {
  constructor( @Inject(PLATFORM_ID) private platformId: any) {
    if(isPlatformBrowser(this.platformId)){
        SuperTokens.init({
          appInfo: {
            appName: "Wasmachen UI",
            apiDomain: environment.supertokens.apiDomain,
            apiBasePath: environment.supertokens.apiBasePath,
          },
          recipeList: [
            Session.init(),
          ],
        });
    }
  }
}
