<div class=" w-full">
  <span class="text-900 p-2 text-4xl font-medium">Similar Events</span>
  <div class='flex flex-wrap gap-1'>
    <div class="mb-3 lg:mb-0 cursor-pointer hover:bg-primary border-1 w-12rem"
         *ngFor='let event of similarEvents | slice:0:visibleEventsCount'
         [routerLink]="['/event', event?.slug]">
      <div class="p-2">
        <div>
          <img [src]='getBackgroundImage(event)' style='width:100px; height: 100px; object-fit: cover;' alt='img'/>
        </div>
        <div class="flex align-items-center justify-content-between mt-3 mb-2">
          <span class="font-medium text-xl">{{event.name}}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf='visibleEventsCount < similarEvents?.length' class='flex flex-row w-full justify-content-end hover:underline cursor-pointer font-bold lg:pr-5'
       (click)='showMoreEvents()'>See more
  </div>

</div>
