<app-super-search [show]='visible1' (hidden)='visible1 = $event'></app-super-search>
<div class="px-4 py-4 md:px-6 lg:px-8 surface-card  flex align-items-center justify-content-between relative lg:static shadow-2 h-4rem md:h-7rem border-round-3xl">
  <img src='../../../../assets/icons/Logo.svg' alt="wasmachen logo" height="80" class="h-3rem lg:h-4rem mr-0 lg:mr-6 cursor-pointer" routerLink="home">
  <a pRipple class="cursor-pointer block lg:hidden text-gray-400" pStyleClass="@next" enterFromClass="hidden" leaveToClass="hidden" [hideOnOutsideClick]="true">
    <i class="pi pi-bars text-4xl"></i>
  </a>
  <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full  left-0 top-100 md:top-50 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800" [ngClass]="{'surface-card': isMobile}">
    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
      <li>
        <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-black-alpha-90 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
           routerLink="discover" >
          <i class="pi pi-home mr-2"></i>
          <span [routerLinkActive]="'underline'" >Discover</span>
        </a>
      </li>
      <li>
        <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-black-alpha-90 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
           routerLink="events" >
          <i class="pi pi-calendar mr-2"></i>
          <span [routerLinkActive]="'underline'">Events</span>
        </a>
      </li>
     <!-- <ng-container *ngIf="(isLoggedIn | async) !== ''">
        <li>
          <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
             routerLink="digest" >
            <i class="pi pi-sun mr-2"></i>
            <span [routerLinkActive]="'underline'">Digest</span>
          </a>
        </li>
      </ng-container>-->
      <ng-container *ngIf="(isLoggedIn | async) !== ''">
        <li>
          <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-black-alpha-90 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
             routerLink="event-gpt" >
            <i class="pi pi-comments mr-2"></i>
            <span [routerLinkActive]="'underline'">EventGPT</span>
          </a>
        </li>
      </ng-container>
    </ul>
    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
      <li class="flex-order-2 lg:flex-order-0">
        <div class="flex px-6 p-3 lg:px-3 lg:py-2">
                    <span class="p-input-icon-left w-full">
                        <i class="pi pi-search text-black-alpha-90"></i>
                        <input type="text" pInputText placeholder="Search" class="text-black-alpha-90 border-gray-800 w-full border-round-3xl" (click)='visible1 = true' />
                    </span>
        </div>
      </li>
      <li *ngIf='!isMobile'>
        <app-language-picker></app-language-picker>
      </li>
      <li class="border-top-1 border-gray-800 border-round-3xl lg:border-top-none">
        <ng-container *ngIf="(isLoggedIn | async) !== ''; else elseBlock">
          <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-black-alpha-90 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
             pStyleClass="@next" enterFromClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true" [hideOnEscape]='true'>
            <i class="pi pi-user mr-2"></i>
            <div>{{(user$ | async)?.firstname }}</div>
          </a>
          <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-8rem cursor-pointer lg:border-1 border-gray-800">
            <li>
              <a pRipple class="flex p-3 align-items-center text-white hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                 routerLink="user">
                <i class="pi pi-user mr-2"></i>
                <span class="font-medium" [routerLinkActive]="'underline'">Profile</span>
              </a>
            </li>
            <li>
              <a pRipple class="flex p-3 align-items-center text-white hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                 (click)="onLogout()">
                <i class="pi pi-sign-out mr-2"></i>
                <span class="font-medium">Signout</span>
              </a>
            </li>
          </ul>
        </ng-container>
        <ng-template #elseBlock>
          <div class="cursor-pointer hover:underline ml-2" (click)="redirectToLogin()">Login</div>
        </ng-template>
      </li>
    </ul>
  </div>
</div>
