import { booleanAttribute, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { debounceTime, distinctUntilChanged, fromEvent, Observable, Subject, switchMap } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { SearchService } from '../services/search.service';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { EventsService } from '@wasmachen/api-connector';

@Component({
  selector: 'app-super-search',
  standalone: true,
  imports: [
    AsyncPipe,
    ChipModule,
    DialogModule,
    FormsModule,
    InputTextModule,
    NgForOf,
    NgIf,
    RouterLink,
  ],
  templateUrl: './super-search.component.html',
  styleUrl: './super-search.component.scss',
})
export class SuperSearchComponent implements OnInit {
  @Input({ transform: booleanAttribute }) show: boolean = false;
  @Output() hidden: EventEmitter<boolean> = new EventEmitter<boolean>();
  loggedIn$ = new Observable<boolean>();

  searchText$ = new Subject<string>();
  events$!: Observable<any>;
  value1: any;
  currentSeconds: number = 0;
  constructor(
    private eventService: EventsService,
    private router: Router,
    private authService: AuthService) {
    this.loggedIn$ = this.authService.hasSession$;
    let date = new Date();
    let milliseconds = date.getTime();
    this.currentSeconds = Math.round(milliseconds / 1000);
  }


  ngOnInit() {
    this.events$ = this.searchText$.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap(searchTerm =>
        this.eventService.superSearch({
          searchString: searchTerm,
          filters: [`startDateTime > ${this.currentSeconds}`],
          sort: ['startDateTime:asc'],
        }),
      ));
  }


  search(searchTerm: string) {
    this.searchText$.next(searchTerm);
  }


  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  hide(){
    this.value1 = '';
    this.show = false;
    this.hidden.next(false);
  }

  openEvent(slug:string){
    this.router.navigate(['/event', slug]);
    this.search(null);
    this.hide();
  }
}
