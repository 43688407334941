import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ChildrenOutletContexts, RouterOutlet } from '@angular/router';
import {
  fadeInAnimation,
  fadeInBottomAnimation,
  slideUpAnimation,
  slideUpBottomAnimation,
} from '../../animations/slide-up.animation';
import { User, UserService } from '@wasmachen/api-connector';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-getting-started-container',
  templateUrl: './getting-started-container.component.html',
  styleUrls: ['./getting-started-container.component.scss'],
  animations: [
    fadeInAnimation,
    fadeInBottomAnimation
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GettingStartedContainerComponent implements OnInit {

  constructor(
    private userService: UserService,
    private contexts: ChildrenOutletContexts,
    private authService: AuthService
  ) {}

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  ngOnInit(){
    this.userService.findOrCreateUser().subscribe((user: User) => {
      this.authService.user$.next(user);
    });
  }

}
