import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit, PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { ScrollerModule } from 'primeng/scroller';
import { SharedModule } from 'primeng/api';
import { Event, EventsService } from '@wasmachen/api-connector';
import { isPlatformBrowser, NgForOf, NgIf, SlicePipe } from '@angular/common';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { RouterLink } from '@angular/router';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'app-similar-events',
  standalone: true,
  imports: [
    ScrollerModule,
    SharedModule,
    NgForOf,
    SlicePipe,
    TruncatePipe,
    RouterLink,
    RippleModule,
    NgIf,
  ],
  templateUrl: './similar-events.component.html',
  styleUrl: './similar-events.component.scss'
})
export class SimilarEventsComponent implements OnInit, OnChanges {
  @Input()
  eventId: string;
  visibleEventsCount = 3;

  similarEvents: Event[];
  isBrowser = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateVisibleEventsCount(event.target.innerWidth);
  }

  constructor(
    private eventService: EventsService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
  }

  ngOnInit() {
    this.update(this.eventId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['eventId'].currentValue){
      if(isPlatformBrowser(this.platformId)){
        this.update(changes['eventId'].currentValue);
        this.updateVisibleEventsCount(window.innerWidth);
      }
    }
  }


  getBackgroundImage(event: Event){
    const url= event?.mediaObjects?.find((mediaObj) => mediaObj?.type === 'image')?.url;
    return url ? url : '/assets/images/arty.jpeg';
  }


  update(eventId: string) {
    this.eventService.getNeighboursForEvent(eventId ?? this?.eventId).subscribe((events) => {
      this.similarEvents = events;
    });
  }

  showMoreEvents() {
    this.visibleEventsCount = this.similarEvents.length;
  }

  updateVisibleEventsCount(width: number) {
    if (width >= 1600) {
      this.visibleEventsCount = 11; // For extra large screens
    } else if (width >= 1200) {
      this.visibleEventsCount = 6; // For large screens
    } else if (width >= 992) {
      this.visibleEventsCount = 4; // For medium screens
    } else if (width >= 768) {
      this.visibleEventsCount = 3; // For small screens
    } else {
      this.visibleEventsCount = 2; // For extra small screens
    }
  }



}
