import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MessageService } from 'primeng/api';

export enum MessageSeverity {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARN = 'WARN'
}

export interface Notification {
  title: string,
  detail: string,
  severity: MessageSeverity,
  life?: number
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  showNotification$: Subject<Notification> = new Subject<Notification>();
  constructor(
  ) {
  }

  showNotification(notification: Notification){
    this.showNotification$.next(notification);
  }
}
