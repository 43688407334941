import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AnimatedIcons } from './animated-icon.enum';
import { AnimationDirection } from 'lottie-web';

@Component({
  selector: 'app-animated-icon',
  standalone: true,
  imports: [
    LottieComponent,
  ],
  templateUrl: './animated-icon.component.html',
  styleUrl: './animated-icon.component.css',
})
export class AnimatedIconComponent implements OnInit, OnChanges {
  @Input() styles!: Partial<CSSStyleDeclaration>;
  @Input() clickIcon!: AnimatedIcons;
  @Input() autoPlay: boolean = false;
  @Input() loop: boolean | number = false;
  @Input() state: boolean = false;

  @Output() loopComplete = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<boolean>();

  lottieOptions: AnimationOptions = {};

  private animationItem!: AnimationItem;
  private isPlaying: boolean = false;
  private internalState = false;

  constructor(private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.lottieOptions = {
      path: this.clickIcon,
      autoplay: this.autoPlay,
      loop: this.loop,
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['state']){
      setTimeout(() => {
        this.setInitialState();
      }, 50);
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    if (this.loop) {
      this.addLoopCompleteListener();
    }
  }

  setInitialState() {
    if(this.internalState && this.state){
      this.playForwardFromStart();
    } else if(this.internalState && !this.state) {
      this.playBackwardsFromEnd();
    } else if(!this.internalState && !this.state) {
      //this.playBackwardsFromEnd();
    } else if(!this.internalState && this.state){
      this.internalState = this.state;
      this.playForwardFromStart();
    } else if(!this.internalState) {
      this.playBackwardsFromEnd();
    }
  }

  playBackwardsFromEnd(){
    this.animationItem.setDirection(-1);
    this.animationItem.goToAndPlay(this.animationItem.totalFrames, true);
  }

  playForwardFromStart() {
    this.animationItem.setDirection(1);
    this.animationItem.goToAndPlay(0, true);
  }

  stop(): void {
    if (this.autoPlay) return;
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.stop();
      this.isPlaying = false;
    });
  }

  click(): void {
    if (this.internalState) {
      this.internalState = false;
      this.play(-1);
    } else {
      this.internalState = true;
      this.play(1);
    }
    this.changed.emit(this.internalState);
  }

  play(direction: AnimationDirection = 1): void {
    if (this.autoPlay || this.isPlaying) return;
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.setDirection(direction);
      this.animationItem.play();
      //this.isPlaying = true;
    });
  }

  addLoopCompleteListener() {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.addEventListener('loopComplete', () => {
        this.loopComplete.emit(true);
        this.isPlaying = false;
      });
    });
  }

  complete() {
    this.isPlaying = false;
    if (!this.loop) {
      this.loopComplete.emit(true);
      this.stop();
    }
  }
}
